import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_operation_log = _resolveComponent("operation-log")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_handle_records = _resolveComponent("handle-records")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "utils__pageContainer action--list" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_tabs, {
        activeKey: _ctx.activeKey,
        "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
        class: "card_tab"
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_component_a_tab_pane, {
            key: 1,
            tab: "操作日志"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_operation_log)
            ]),
            _: 1
          })),
          (_openBlock(), _createBlock(_component_a_tab_pane, {
            key: 2,
            tab: "批量处理记录"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_handle_records)
            ]),
            _: 1
          }))
        ]),
        _: 1
      }, 8, ["activeKey"])
    ]),
    _: 1
  }))
}