import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_app_table = _resolveComponent("app-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_form, {
      class: "fl-clean filterForm actionLog__filterForm",
      model: _ctx.filterParams,
      layout: 'inline'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, { label: "操作人" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              value: _ctx.filterParams.operatorPhone,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterParams.operatorPhone) = $event)),
              "allow-clear": "",
              placeholder: "请输入"
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "功能模块" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              value: _ctx.filterParams.processType,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filterParams.processType) = $event)),
              "allow-clear": "",
              style: {"width":"200px"},
              placeholder: "请选择"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select_option, { value: "vehicleQuotaAdjustImport" }, {
                  default: _withCtx(() => [
                    _createTextVNode("批量调整车辆额度")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "操作时间" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_range_picker, {
              value: _ctx.filterParams.dateRange,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filterParams.dateRange) = $event)),
              format: "YYYY-MM-DD"
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              ghost: "",
              onClick: _ctx.onRefreshTable
            }, {
              default: _withCtx(() => [
                _createTextVNode("查询")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_app_table, {
      ref: "tableRef",
      size: "default",
      "row-key": "id",
      columns: _ctx.tableColumns,
      data: _ctx.loadTableData,
      "show-pagination": true,
      "page-refresh": false
    }, {
      action: _withCtx(({ record }) => [
        (record.processResult !== 1)
          ? (_openBlock(), _createBlock(_component_a_button, {
              key: 0,
              type: "link",
              onClick: ($event: any) => (_ctx.handleDownload(record.id))
            }, {
              default: _withCtx(() => [
                _createTextVNode("下载处理文件")
              ]),
              _: 2
            }, 1032, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["columns", "data"])
  ]))
}