
import { message } from 'ant-design-vue';
import { defineComponent, onMounted, reactive, ref, getCurrentInstance } from 'vue';
import moment, { Moment } from 'moment';
import { settleTree, not } from '@/utils/illuminate';

import type { TableColumn } from '@/components/Table';
import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';
import { formatDate } from '@/core/filters';

export default defineComponent({
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const filterParams = reactive<{
      userName: string;
      menuId: string;
      dateRange: Moment[];
    }>({
      userName: '',
      menuId: '',
      dateRange: [],
    });

    // 加载数据方法 必须为 Promise 对象
    const loadTableData = async (parameter: any) => new Promise<void>((resolve, reject) => {
      const params: any = not(filterParams, ['dateRange']);

      if (filterParams.dateRange.length) {
        params.startTime = moment(filterParams.dateRange[0].format('YYYY-MM-DD 00:00:00')).valueOf();
        params.endTime = moment(filterParams.dateRange[1].format('YYYY-MM-DD 23:59:59')).valueOf();
      }

      request.get(createApiUrl('/newlinkSass/log/find-ask-for-enterprise-user-list'), {
        params: { ...params, ...parameter },
      })
        .then((res: any) => {
          resolve(res.data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const tableColumns: TableColumn[] = [
      { title: '功能模块', dataIndex: 'menuName' },
      { title: '操作类型', dataIndex: 'uriRemark' },
      { title: '操作人', dataIndex: 'operationUser' },
      { title: '操作时间', dataIndex: 'createTime', customRender: ({ text }) => formatDate(text) },
    ];

    const tableRef = ref<any>(null as any);
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    const menuTree = ref<any[]>([]);

    const loadMenuTree = async () => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/enterprise/find-user-role-menu'));
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      menuTree.value = settleTree(res.data, {
        value: 'menuId',
        title: 'menuName',
        label: 'menuName',
      }, 'list');
    };

    onMounted(() => {
      instance?.$sensors.track('es_saas_log_page_view', { page_name: '操作日志页_浏览' });
      loadMenuTree();
    });

    return {
      filterParams,
      tableColumns,

      tableRef,
      loadTableData,
      onRefreshTable,

      menuTree,
    };
  },
});
