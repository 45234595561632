
import { defineComponent, onMounted, reactive, ref, getCurrentInstance } from 'vue';

import { message } from 'ant-design-vue';
import { useCapacity } from '@/views/auto/utils';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';

export default defineComponent({
  name: 'DisplacementView',
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const capacityState = useCapacity();
    const editing = ref(false);

    const defaultData = {
      t: Array(10).fill({ id: 0, displacement: '--', oilConsumption: '--' }),
      l: Array(10).fill({ id: 0, displacement: '--', oilConsumption: '--' }),
    };

    const formData = reactive({
      ...defaultData,
    });

    const loadData = async () => {
      await capacityState.loadCapacity();
      defaultData.t = capacityState.capacityList.value.filter((item: any) => /T$/i.test(item.displacement));
      defaultData.l = capacityState.capacityList.value.filter((item: any) => /L$/i.test(item.displacement));
      Object.assign(formData, defaultData);
    };

    // = ---------------------------- = 提交 = ---------------------------- =
    const submiting = ref(false);
    const onSubmit = async () => {
      if (formData.t.some((item: { oilConsumption: string | number }) => +item.oilConsumption <= 0)
        || formData.l.some((item: { oilConsumption: string | number }) => +item.oilConsumption <= 0)) {
        return message.error('油耗需大于0');
      }

      try {
        await request.post(createApiUrl('/newlinkSass/vehicle/batch/update'), [...formData.t, ...formData.l]);
      } catch (e: any) {
        return message.error(e.message);
      } finally {
        submiting.value = false;
      }

      editing.value = false;
      loadData();
      message.success('保存完成');
    };

    const onCancel = () => {
      editing.value = false;
      Object.assign(formData, defaultData);
    };

    onMounted(() => {
      instance?.$sensors.track('es_saas_mpg_page_view', { page_name: '油补油耗配置页_浏览' });
      loadData();
    });

    return {
      editing,
      formData,

      submiting,
      onSubmit,
      onCancel,
    };
  },
});
