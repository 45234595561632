
import { message } from 'ant-design-vue';
import { defineComponent, reactive, ref, getCurrentInstance, onMounted } from 'vue';
import moment, { Moment } from 'moment';
import { dispatchDownload, not } from '@/utils/illuminate';

import type { TableColumn } from '@/components/Table';
import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';
import { formatDate } from '@/core/filters';

export default defineComponent({
  props: {
    operationType: {
      type: Number,
      default: 1,
    },
  },
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    enum ProcessResultEnum {
      全部失败 = -1,
      全部成功 = 1,
      部分失败 = 0,
    }
    const filterParams = reactive<{
      operatorPhone: any;
      processType: any;
      dateRange: Moment[];
    }>({
      operatorPhone: undefined,
      processType: undefined,
      dateRange: [],
    });

    // 加载数据方法 必须为 Promise 对象
    const loadTableData = async (parameter: any) => new Promise<void>((resolve, reject) => {
      const params: any = not(filterParams, ['dateRange']);

      if (filterParams.dateRange.length) {
        params.processTimeBegin = moment(filterParams.dateRange[0].format('YYYY-MM-DD 00:00:00')).valueOf();
        params.processTimeEnd = moment(filterParams.dateRange[1].format('YYYY-MM-DD 23:59:59')).valueOf();
      }

      request.post(createApiUrl('/newlinkSass/batchProcess/recordPageList'), {
        ...params,
        ...parameter,
      })
        .then((res: any) => {
          resolve(res.data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const tableColumns: TableColumn[] = [
      { title: '上传时间', dataIndex: 'processTime', customRender: ({ text }) => formatDate(text) },
      { title: '执行项目', dataIndex: 'processName' },
      { title: '操作人', dataIndex: 'operatorUserName' },
      { title: '执行结果', dataIndex: 'processResult', customRender: ({ text }) => ProcessResultEnum[text] },
      { title: '操作', width: '112px', align: 'center', slots: { customRender: 'action' }, fixed: 'right' },
    ];

    const tableRef = ref<any>(null as any);
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };
    // 下载处理文件
    const handleDownload = async (recordId: any) => {
      const params = {
        recordId,
      };
      let res: any;
      try {
        res = await request.post(createApiUrl('/newlinkSass/batchProcess/exportErrorResult'), params, { responseType: 'blob', noEnterpriseId: true });
      } catch (e: Error & any) {
        return message.error(e.message);
      }
      const blob = new Blob([res.data], {
        type: 'application/octet-stream;charset=UTF-8',
        endings: 'transparent',
      });

      const url = URL.createObjectURL(blob);
      const fileName = '处理结果文件.xlsx';
      dispatchDownload(fileName, url);
      URL.revokeObjectURL(url);
    };

    onMounted(() => {
      instance?.$sensors.track('es_saas_log_batch_page_view', { page_name: '操作日志_批量处理记录页_浏览' });
    });

    return {
      filterParams,
      tableColumns,
      handleDownload,
      tableRef,
      loadTableData,
      onRefreshTable,
    };
  },
});
