
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import operationLog from './OperationLog.vue';
import handleRecords from './HandleRecords.vue';

export default defineComponent({
  name: 'RoleListView',
  components: {
    operationLog,
    handleRecords,
  },
  setup(props, ctx) {
    const route = useRoute();
    const activeKey = ref(1);
    activeKey.value = Number(route.params.activeKey) || 1;
    return {
      activeKey,
    };
  },
});
